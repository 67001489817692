import type { PropType } from 'vue';
import { Transition } from 'vue';
import styles from '~/components/publicCom/Menu/css/smMenuItem.module.scss';
import MdiKeyboardArrowRight from '~icons/mdi/keyboard-arrow-right';
import { Icons } from '#components';

export interface IMenuItemProps {
  name: string;
  isTitle?: boolean;
  icon?: string;
  route?: string;
  children?: IMenuItemProps[];
}

export default defineComponent({
  name: 'SmMenuItem',
  props: {
    isSidebarOpen: {
      type: Boolean,
    },
    item: {
      type: Object as PropType<IMenuItemProps>,
      default: () => {},
    },
  },
  setup(props: { item: IMenuItemProps; isSidebarOpen: boolean }) {
    const expand = ref(false);
    const route = useRoute();

    const isParentsActive = computed(() => {
      return props.item.route === route.path;
    });

    const onClickExpand = () => {
      props.item.children?.length ? (expand.value = !expand.value) : onClickTo(props.item.route!);
    };

    const onClickTo = (route: string) => {
      navigateTo(route);
    };

    // const onClickModule = (target: IMenuItemDto) => {
    //   props.onClickTo(`${target.route}${target.id}`);
    // };

    const isActive = (item: IMenuItemProps) => {
      return item.route === route.path;
    };

    return () => {
      return (
        <li
          class={`${styles.container} relative box-border z-1 select-none cursor-pointer m-0 c-fontPrimary w-100% py-8px`}
        >
          <div onClick={onClickExpand} class={`${styles.item}  flex-items-center justify-between w-100% `}>
            <div class={` ${isParentsActive.value ? styles.itemActive : ''} flex-items-center pl-20px box-border`}>
              <Icons name={props.item.icon}> </Icons>
              <span v-show={props.isSidebarOpen} class="ml-8px font-size-16px color-inherit ">
                {props.item.name}
              </span>
            </div>
            <MdiKeyboardArrowRight
              v-show={props.isSidebarOpen}
              class={`mr-6px ${styles.arrow} ${expand.value ? 'rotate-90' : ''} ${props.item?.children?.length ? '' : 'hidden'}`}
            ></MdiKeyboardArrowRight>
          </div>

          <Transition
            enterFromClass={styles.fadeEnterFrom}
            leaveToClass={styles.fadeLeaveTo}
            enterActiveClass={styles.fadeEnterActive}
            leaveActiveClass={styles.fadeLeaveActive}
            mode="out-in"
          >
            <div
              v-show={expand.value}
              class={`${styles.children} ${!props.isSidebarOpen ? 'pl-6px!' : ''}  pl-12px mt-8px box-border  transition-padding-300`}
            >
              {props.item.children?.map((item) => {
                return (
                  <div
                    onClick={() => onClickTo(item.route!)}
                    class={`cursor-pointer flex-items-center pl-15px rounded-8px overflow-hidden py-8px  c-fontPrimary  hover:(c-primary) ${isActive(item) ? styles.itemActive : ''} ${styles.childrenItem} }`}
                  >
                    <Icons name={item.icon}></Icons>
                    <span v-show={props.isSidebarOpen} class="ml-8px font-size-16px color-inherit ">
                      {item.name}
                    </span>
                  </div>
                );
              })}
            </div>
          </Transition>
        </li>
      );
    };
  },
});
